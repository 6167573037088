import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import accountRoutes from '../modules/account/routes';
import agendaDashboardRoutes from '../modules/agendaDashboard/routes';
import documentDashboardRoutes from '../modules/documentDashboard/routes';
import documentDetailRoutes from '../modules/documentDetail/routes';
import listRoutes from '../modules/listManager/routes';

import MaintenanceNotice from '../views/MaintenanceNotice.vue';

// https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  ...accountRoutes,
  ...agendaDashboardRoutes,
  ...documentDashboardRoutes,
  ...documentDetailRoutes,
  ...listRoutes,
  // Temporary disabled due to mockup data see imports above
  // ...documentFilterRoutes,
  {
    path: '/maintenance',
    name: 'MaintenanceNotice',
    component: MaintenanceNotice,
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return {};
  },
  routes,
});

// We need to weed out all routes that are not authenticated. All
// routes that are not authenticated will be redirected to login :)
// When already logged in routes directed to 'login' will be redirected to dashboard.
const unauthenticatedRoutes = ['login', 'MaintenanceNotice'];
router.beforeEach((to, from, next) => {
  const routeRequiresAuth = !unauthenticatedRoutes.includes(to.name);
  if (routeRequiresAuth && !store.getters.authIsAuthenticated) next({ name: 'login' });

  else if (to.name === 'nlDashboard') {
    // Guard for if the user has no access to the nl dashboard
    // Note: We are currenlty only using MC11 as a proxy for access to the nlDashboard. However any
    // single document module on this dashboard *should* give access to the dashboard.
    // (DS, DM, SB, etc...)
    if (store.getters.authHasModuleAccess('MC11')) next(); // Don't redirect, actually go to '/nl/dashboard'
    else if (store.getters.authHasModuleAccess('MC13')) next('/nl-lr/dashboard');
    else if (store.getters.authHasModuleAccess('MC07')) next('/eu/dashboard');
    else {
      // eslint-disable-next-line no-console
      console.error('Account has no access to any standalone dashboard!');
      store.dispatch('logout'); // Immediately log out as the user is not able to log out.
      store.dispatch('setMessage', {
        message: 'Dit account mag niet inloggen omdat het geen toegang heeft tot het Polpo Dashboard.\n Neem contact op met Polpo suppport.',
        type: 'error',
      }, { root: true });
      next({ name: 'login' });
    }
  } else next();
});

export default router;
