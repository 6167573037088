/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import savedSearchesApiConstructor from '@/api/document/document-api-searches';

const savedSearchesApi = savedSearchesApiConstructor('eu');

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  euAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  euAllSavedSearches: [],

  euMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  euCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    rssEnabled: false,
    queryDetails: {
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      euEpCommittees: [],
      query: null,
    },
  },
};

// getters
const getters = {
  euAllRecentSearches: (state) => state.euAllRecentSearches,
  euAllSavedSearches: (state) => state.euAllSavedSearches,
  euCurrentSearch: (state) => state.euCurrentSearch,
  euMaxNumberOfSearches: (state) => state.euMaxNumberOfSearches,
};

// actions
const actions = {
  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async euGetAllSavedSearches({ state, commit, rootState }, payload) {
    const response = await savedSearchesApi.getAll();
    commit('euSetSavedSearches', response.data);
    commit('euMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async euSaveSearch({ commit, state }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await savedSearchesApi.update(search);
    } else {
      response = await savedSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('euGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async euSaveSearches({ commit, state }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await savedSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('euGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async euUpdateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('euSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await savedSearchesApi.update(state.euCurrentSearch);

    this.dispatch('euGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async euRemoveSearch({ commit, state }, search) {
    const response = await savedSearchesApi.delete(search);
    this.dispatch('euGetAllSavedSearches');
    return response;
  },

  async euSetCurrentSearch({ commit }, search) {
    commit('euSetCurrentSearch', search);
  },

  euResetCurrentSearch({ commit }) {
    commit('euResetCurrentSearch');
  },

  async euSetRecentSearches({ commit }, searches) {
    commit('euSetRecentSearches', searches);
  },
};

// mutations
const mutations = {
  euSetCurrentSearch(state, search) {
    state.euCurrentSearch = search;
  },
  euSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.euAllSavedSearches = searches;
  },
  euAddSavedSearch(state, search) {
    state.euAllSavedSearches.data.unshift(search);
  },
  euSetRecentSearches(state, searches) {
    state.euAllRecentSearches = searches;
  },
  euMaxNumberOfSearches(state, payload) {
    state.euMaxNumberOfSearches = payload;
  },
  euAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;

    // Add to the top
    state.euAllRecentSearches.unshift(recentSearch);

    if (state.euAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.euAllRecentSearches.splice(-1, 1);
    }
  },
  euSyncCurrentSearch(state, { searchParameters }) {
    state.euCurrentSearch.queryDetails = searchParameters;
    state.euCurrentSearch.queryDetails.query = searchParameters.query;
  },
  euResetCurrentSearch(state) {
    state.euCurrentSearch = {
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
      rssEnabled: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
