import Config from '@/config';
// import router from '@/router';
import timelineAPIConstructor from '@/api/document/document-api-timeline';

const timelineAPI = timelineAPIConstructor('eu');

// initial state
const state = {
  euSearchMoreAfter: null,

  euTimelineData: {
    data: [],
    countTotal: 0,
    hasMore: false,
  },
  euMaxDateRangeTimelineItemsTotal: 0,
};

// getters
const getters = {
  euSearchMoreAfter: (state) => state.euSearchMoreAfter,
  euTimelineItems: (state) => state.euTimelineData.data,
  euTimelineItemsCountTotal: (state) => state.euTimelineData.countTotal,
  euTimelineItemsHasMore: (state) => state.euSearchMoreAfter
    && (state.euTimelineData.data.length < state.euTimelineData.countTotal),
  euMaxDateRangeTimelineItemsHasMore: (state) => state.euNextItemUrl
      && (state.euTimelineData.data.length < state.euMaxDateRangeTimelineItemsTotal),
  euMaxDateRangeTimelineItemsTotal: (state) => state.euMaxDateRangeTimelineItemsTotal,
};

// actions
const actions = {
  euSetMaxDateRangeTimelineItemsTotal({ commit }, total) {
    commit('euSetMaxDateRangeTimelineItemsTotal', total);
  },

  euSetSearchMoreAfter({ commit }, url) {
    commit('euSetSearchMoreAfter', url);
  },

  // Although getEntityByUrl is asynchronous, it needs to be fired synchronous since the
  // items are sorted by time.
  async euGetAllTimelineItemsNext({ state, commit, rootState }) {
    // Check if euSearchMoreAfter is available
    if (state.euSearchMoreAfter) {
      const { searchParameters } = rootState;
      const response = await timelineAPI.getTimelineItems(
        { ...searchParameters, searchAfter: state.euSearchMoreAfter },
      );
      if (!response) {
        commit('setMessage', {
          message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
          type: 'info',
        });
        return;
      }
      if (response.data.countTotal > 0 && response.data.searchMoreAfter) {
        commit('euSetSearchMoreAfter', response.data.searchMoreAfter);
        commit('euAppendTimelineItems', response.data);
      }
    }
  },

  async euGetAllTimelineItems({ commit, rootState }) {
    // Lets see how many times we have been called
    const { searchParameters } = rootState;
    const {
      query,
      groupPaths,
      groupingDetails,
      euEpCommittees,
    } = searchParameters;

    commit('euClearTimelineItems');

    // Set lookBack to false to avoid button will be drawn when switching to
    // other date range
    this.dispatch('setLookBack', false);

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: 'Geef een onderwerp op',
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a query has been set.
    if (query.length < Config.minimalQueryLength) {
      commit('setMessage', {
        message: `Onderwerp moet uit minimaal ${Config.minimalQueryLength} karakters bestaan`,
        type: 'info',
      }, { root: true });

      return;
    }

    // if (router.currentRoute.name === 'euDashboard' && !skipHistory) {
    // Add query to recent searches
    commit('euAddRecentSearch', {
      queryDetails: {
        query,
        groupPaths,
        groupingDetails,
        euEpCommittees,
      },
    }, { root: true });
    // Set current search to null so none of the saved searches are shown as the active search
    commit('euResetCurrentSearch');
    // }

    const response = await timelineAPI.getTimelineItems(
      { ...searchParameters, includeFullHistoryCount: true },
    );
    if (!response) {
      commit('setMessage', {
        message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
        type: 'info',
      });
      return;
    }

    commit('euSetTimelineItems', response.data);

    if (response.data.searchMoreAfter) {
      commit('euSetSearchMoreAfter', response.data.searchMoreAfter);
    }
    if (response.data.fullHistoryCount) {
      commit('euSetMaxDateRangeTimelineItemsTotal', response.data.fullHistoryCount);
    }

    // Fetch trend, stakeholders and trending topics
    this.dispatch('euGetStatistics');

    if (response.data.countTotal === 0) {
      this.dispatch('setLookBack', true);
      commit('setMessage', {
        message: 'Geen resultaten gevonden',
        type: 'info',
      });
    }
  },

  euClearTimelineItems({ commit }) {
    commit('euClearTimelineItems');
  },
};

// mutations
const mutations = {
  euSetMaxDateRangeTimelineItemsTotal(state, total) {
    state.euMaxDateRangeTimelineItemsTotal = total;
  },

  euSetSearchMoreAfter(state, euSearchMoreAfter) {
    state.euSearchMoreAfter = euSearchMoreAfter;
  },

  euSetTimelineItems(state, timelineData) {
    state.euTimelineData = timelineData;
    state.euTimelineData.hasMore = !!((timelineData.actions && timelineData.actions.more));
  },

  euAppendTimelineItems(state, timelineData) {
    state.euTimelineData.data = [...state.euTimelineData.data, ...timelineData.data];
  },

  euClearTimelineItems(state) {
    state.euTimelineData = {
      data: [],
      countTotal: 0,
      hasMore: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
