import Config from '@/config';
import router from '@/router';
import timelineAPIConstructor from '@/api/document/document-api-timeline';

const timelineAPI = timelineAPIConstructor('nl');

// initial state
const state = {
  nlLrSearchMoreAfter: null,

  nlLrTimelineData: {
    data: [],
    countTotal: 0,
    hasMore: false,
  },
  nlLrMaxDateRangeTimelineItemsTotal: 0,
};

// getters
const getters = {
  nlLrSearchMoreAfter: (state) => state.nlLrSearchMoreAfter,
  nlLrTimelineItems: (state) => state.nlLrTimelineData.data,
  nlLrTimelineItemsCountTotal: (state) => state.nlLrTimelineData.countTotal,
  nlLrTimelineItemsHasMore: (state) => state.nlLrSearchMoreAfter
    && (state.nlLrTimelineData.data.length < state.nlLrTimelineData.countTotal),
  nlLrMaxDateRangeTimelineItemsHasMore: (state) => state.nlLrNextItemUrl
    && (state.nlLrTimelineData.data.length < state
      .nlLrMaxDateRangeTimelineItemsTotal),
  nlLrMaxDateRangeTimelineItemsTotal: (state) => state
    .nlLrMaxDateRangeTimelineItemsTotal,
};

// actions
const actions = {
  nlLrSetMaxDateRangeTimelineItemsTotal({ commit }, total) {
    commit('nlLrSetMaxDateRangeTimelineItemsTotal', total);
  },

  nlLrSetSearchMoreAfter({ commit }, url) {
    commit('nlLrSetSearchMoreAfter', url);
  },

  // Although getEntityByUrl is asynchronous, it needs to be fired synchronous since the
  // items are sorted by time.
  async nlLrGetAllTimelineItemsNext({ state, commit, rootState }) {
    // Check if nlLrSearchMoreAfter is available
    if (state.nlLrSearchMoreAfter) {
      const { searchParameters } = rootState;
      const response = await timelineAPI.getTimelineItems(
        { ...searchParameters, searchAfter: state.nlLrSearchMoreAfter },
      );
      if (!response) {
        commit('setMessage', {
          message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
          type: 'info',
        });
        return;
      }
      if (response.data.countTotal > 0 && response.data.searchMoreAfter) {
        commit('nlLrSetSearchMoreAfter', response.data.searchMoreAfter);
        commit('nlLrAppendTimelineItems', response.data);
      }
    }
  },

  async nlLrGetAllTimelineItems({ commit, rootState }, skipHistory) {
    // Lets see how many times we have been called
    const { searchParameters } = rootState;
    const {
      query,
      groupPaths,
      groupingDetails,
      committees,
    } = searchParameters;

    commit('nlLrClearTimelineItems');

    // Reset all errors
    commit('clearMessages');

    // Set lookBack to false to avoid button will be drawn when switching to
    // other date range
    this.dispatch('setLookBack', false);

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: 'Geef een onderwerp op',
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a query has been set.
    if (query.length < Config.minimalQueryLength) {
      commit('setMessage', {
        message: `Onderwerp moet uit minimaal ${Config.minimalQueryLength} karakters bestaan`,
        type: 'info',
      }, { root: true });

      return;
    }

    // Remove any errors shown
    commit('clearMessages');

    // Mockupdata disable
    if (router.currentRoute.name === 'nlLrDashboard' && !skipHistory) {
      // Add query to recent searches
      commit('nlLrAddRecentSearch', {
        queryDetails: {
          query,
          groupPaths,
          groupingDetails,
          committees,
        },
      }, { root: true });
      // Set current search to null so none of the saved searches are shown as the active search
      commit('nlLrResetCurrentSearch');
    }

    const response = await timelineAPI.getTimelineItems(
      { ...searchParameters, includeFullHistoryCount: true },
    );
    if (!response) {
      commit('setMessage', {
        message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
        type: 'info',
      });
      return;
    }

    commit('nlLrSetTimelineItems', response.data);

    if (response.data.searchMoreAfter) {
      commit('nlLrSetSearchMoreAfter', response.data.searchMoreAfter);
    }
    if (response.data.fullHistoryCount) {
      commit('nlLrSetMaxDateRangeTimelineItemsTotal', response.data.fullHistoryCount);
    }

    // Fetch trend, stakeholders and trending topics
    this.dispatch('nlLrGetStatistics');

    if (response.data.countTotal === 0) {
      this.dispatch('setLookBack', true);
      commit('setMessage', {
        message: 'Geen resultaten gevonden',
        type: 'info',
      });
    }
  },

  nlLrClearTimelineItems({ commit }) {
    commit('nlLrClearTimelineItems');
  },
};

// mutations
const mutations = {
  nlLrSetMaxDateRangeTimelineItemsTotal(state, total) {
    state.nlLrMaxDateRangeTimelineItemsTotal = total;
  },

  nlLrSetSearchMoreAfter(state, nlLrSearchMoreAfter) {
    state.nlLrSearchMoreAfter = nlLrSearchMoreAfter;
  },

  nlLrSetTimelineItems(state, timelineData) {
    state.nlLrTimelineData = timelineData;
    state.nlLrTimelineData
      .hasMore = !!((timelineData.actions && timelineData.actions.more));
  },

  nlLrAppendTimelineItems(state, timelineData) {
    state.nlLrTimelineData.data = [...state
      .nlLrTimelineData.data, ...timelineData.data];
  },

  nlLrClearTimelineItems(state) {
    state.nlLrTimelineData = {
      data: [],
      countTotal: 0,
      hasMore: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
