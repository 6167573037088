// Vue
import Vue from 'vue';
import Vuex from 'vuex';

// Vuex logger
import createLogger from 'vuex/dist/logger';

// App specific
import { accountStoreModule } from '../modules/account';
import {
  agendaStoreModule,
  agendaSearchesStoreModule,
} from '../modules/agendaDashboard';
import {
  documentStoreModule,
  documentSearchesStoreModule,
  documentStatisticsStoreModule,
  documentTimelineStoreModule,
} from '../modules/documentDashboard/nlDashboard';
import {
  euDocumentStoreModule,
  euSearchesStoreModule,
  euStatisticsStoreModule,
  euTimelineStoreModule,
} from '../modules/documentDashboard/euDashboard';
import {
  listStoreModule,
  listItemStoreModule,
} from '../modules/listManager';
import {
  nlLrDocumentStoreModule,
  nlLrSearchesStoreModule,
  nlLrStatisticsStoreModule,
  nlLrTimelineStoreModule,
} from '../modules/documentDashboard/nlLrDashboard';

import authentication from './modules/authentication';
import autocomplete from './modules/autocomplete';
import browser from './modules/browser';
import busy from './modules/busy';
import confirmDialog from './modules/confirmDialog';
import fileStore from './modules/file-store';
import messages from './modules/messages';
import referrer from './modules/referrer';
import searchParameters from './modules/searchParameters';
import visibility from './modules/visibility';
import emailUpdate from './modules/emailUpdate';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';
const debug = false;

export default new Vuex.Store({
  modules: {
    accountStoreModule,
    agendaStoreModule,
    agendaSearchesStoreModule,
    documentStoreModule,
    documentSearchesStoreModule,
    documentTimelineStoreModule,
    documentStatisticsStoreModule,
    euDocumentStoreModule,
    euSearchesStoreModule,
    euStatisticsStoreModule,
    euTimelineStoreModule,
    listStoreModule,
    listItemStoreModule,
    nlLrDocumentStoreModule,
    nlLrSearchesStoreModule,
    nlLrStatisticsStoreModule,
    nlLrTimelineStoreModule,
    autocomplete,
    authentication,
    busy,
    browser,
    confirmDialog,
    fileStore,
    messages,
    referrer,
    searchParameters,
    visibility,
    emailUpdate,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
