import Vue from 'vue';
import VueI18n from 'vue-i18n';

import sharedTranslations from '../modules/shared';
import { accountTranslations } from '../modules/account';
import { agendaDashboardTranslations } from '../modules/agendaDashboard';
import { listTranslations } from '../modules/listManager';
import { nlLrDashboardTranslations } from '../modules/documentDashboard/nlLrDashboard';
import { nlDashboardTranslations } from '../modules/documentDashboard/nlDashboard';
import { euDashboardTranslations } from '../modules/documentDashboard/euDashboard';

const languages = {
  en: {
    ...sharedTranslations.en,
    ...accountTranslations.en,
    ...agendaDashboardTranslations.en,
    ...listTranslations.en,
    ...nlLrDashboardTranslations.en,
    ...nlDashboardTranslations.en,
    ...euDashboardTranslations.en,
  },
  nl: {
    ...sharedTranslations.nl,
    ...accountTranslations.nl,
    ...agendaDashboardTranslations.nl,
    ...listTranslations.nl,
    ...nlLrDashboardTranslations.nl,
    ...nlDashboardTranslations.nl,
    ...euDashboardTranslations.nl,
  },
};

const messages = { ...languages };

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages,
});
