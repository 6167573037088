import axios from 'axios';
import store from '@/store';
import router from '../router';

/**
 * Fired before each request, attaches X-auth header
 * to each request when session token is available
 */
axios.interceptors.request.use((config) => {
  const result = config;
  const sessionToken = store.getters.authSessionToken;

  if (sessionToken) {
    result.headers['X-Auth'] = sessionToken;
  }

  store.dispatch('setGlobalLoaderBusy', true, { root: true });
  return result;
});

/**
 * Checks each response for errors. If a 401 is received
 * the user is redirected to the site root
 */
axios.interceptors.response.use((response) => {
  store.dispatch('setGlobalLoaderBusy', false, { root: false });
  return response;
}, (error) => {
  let message;
  store.dispatch('setGlobalLoaderBusy', false, { root: false });
  let redirectToLogin = false;
  let redirectToMaintenance = false;
  let afterRedirect;

  if (!error.response) {
    message = 'Helaas! Het lukt ons niet om verbinding met je te maken. Ben je verbonden met het internet?';
  } else {
    const { data } = error.response;
    const dataMessage = data.message;
    switch (error.response.status) {
      case 500:
        message = 'Er is een serverfout opgetreden';
        break;
      case 400:
        if (data.errorCode === 'EC-04') {
          const hasAgendaAccess = store.getters.authHasModuleAccess('MC05');
          if (hasAgendaAccess && (router.currentRoute.name !== 'nlDashboard')) {
            message = 'Geen vergaderingen gevonden';
          }
        } else {
          message = dataMessage;
        }
        break;
      case 401:
        // We don't know who you are, loggin in will help.
        redirectToLogin = true;
        message = 'Je gebruikersnaam of wachtwoord is verkeerd ingevuld.';
        break;
      case 403:
        // We know who you are, but you don't have access,
        // loggin in again will reset your local module cache.
        if (data.errorCode === 'EC-01') {
          message = 'Je hebt geprobeerd een pagina te bereiken waar je geen toegang (meer) toe hebt.';
          redirectToLogin = true;
          afterRedirect = 'nlDashboard';
        } else {
          message = dataMessage;
        }
        break;
      case 404:
        message = dataMessage;
        break;
      case 429:
        message = 'Je hebt zojuist een activatiemail verzonden. probeer het over 5 minuten nog eens.';
        break;
      case 503:
        // When maintenance mode is enabled in Heroku it sends a 503-code,
        // then we use the content of heroku's HTML response to distinguish it from
        // a 'normal' 503 from our own backend.
        // Note: This does not work on DEV as it runs into CORS errors since the FE and BE
        // do not share the same domain.
        if (
          typeof error?.response?.data === 'string'
          && error.response.data.includes('<title>Offline for Maintenance</title>')
        ) {
          redirectToMaintenance = true;
        } else {
          message = dataMessage;
        }
        break;
      default:
        break;
    }
  }

  if (message) {
    store.dispatch('setMessage', {
      message,
      type: 'info',
    }, { root: true });
  }

  if (redirectToMaintenance) {
    router.push({ name: 'MaintenanceNotice' });
  } else if (redirectToLogin) {
    store.dispatch('clearCache');
    router.push({ name: 'login', params: (afterRedirect ? { redirectTo: afterRedirect } : {}) });
  }
  return Promise.reject(error);
});
